import { styled } from '@stitches/react';
import { FetchCartAndNotifyAll } from 'Commerce/Cart/Cart';
import { GetShippingDestination } from 'Commerce/CountryPicker/ShippingDestination';
import { useVariantData } from 'context/variant.context';
import { setShippingCountry } from '../Checkout/checkout.requests';
import { useAppSettings, useTranslations } from '../../../context/init-data.context';
import { useHttpClient } from '../../../lib/requests/HttpClientProvider';
import { useRouter } from '../../../lib/router/router-hook';

function SwitchShippingCountryCTA() {
  const {
    productLabels: { yourSelectedShippingCountryIs, switchShippingCountryTo },
  } = useTranslations();
  const { state } = useVariantData();
  const selectedVariant = state.selectedVariant;
  const { languageRoute } = useAppSettings();
  const currentShippingDestination = GetShippingDestination();
  const client = useHttpClient();
  const router = useRouter();

  if (
    selectedVariant == null ||
    !selectedVariant.shippingNotAllowed ||
    selectedVariant.allowedShippingCountry == null
  ) {
    return <></>;
  }

  async function handleClick(e: React.MouseEvent) {
    e.preventDefault();
    const code = selectedVariant?.allowedShippingCountry?.code;
    if (!code) return;

    await setShippingCountry(client, code);
    await FetchCartAndNotifyAll(languageRoute);

    router.reload();
  }

  return (
    <Wrapper>
      {yourSelectedShippingCountryIs} {currentShippingDestination.name}
      {'. '}
      <CTALink href="#" onClick={handleClick}>
        {switchShippingCountryTo} {selectedVariant.allowedShippingCountry.name}
      </CTALink>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  fontSize: '$fontSize75',
  textAlign: 'center',
  lineHeight: '$lh16',
  marginTop: '5px',
  fontWeight: '$fontWeightRegular',
});

const CTALink = styled('a', {
  color: '$onInteractiveEmphasized',
  fontWeight: '600',
});

export default SwitchShippingCountryCTA;
